
const state = () => ({
	menus: {
		header: [
			{
				label: 'Wheat',
				link: {
					name: 'seeds',
					params: {
						seed: 'wheat',
					},
				},
			},
			{
				label: 'Tricale',
				link: {
					name: 'seeds',
					params: {
						seed: 'tricale',
					},
				},
			},
			{
				label: 'Oats',
				link: {
					name: 'seeds',
					params: {
						seed: 'oats',
					},
				},
			},
			{
				label: 'Rye',
				link: {
					name: 'seeds',
					params: {
						seed: 'rye',
					},
				},
			},
			{
				label: 'Cover Crops',
				link: {
					name: 'seeds',
					params: {
						seed: 'cover',
					},
				},
			}, {
				label: 'Contact Us',
				link: {
					name: 'contact',
				},
			},
		]
	}
})

const actions = {}

const mutations = {}

const getters = {
	menu: state => key => menus[key],
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
