
/// Views
const Main = () => import(/* webpackChunkName: "main" */ '@/views/Main')

/// Partials
const Home = () => import(/* webpackChunkName: "main" */ '@/views/partials/Home')
const Seed = () => import(/* webpackChunkName: "seed" */ '@/views/partials/Seed')
const Contact = () => import(/* webpackChunkName: "contact" */ '@/views/partials/Contact')

/// Components
const MainFooter = () => import(/* webpackChunkName: "main" */ '@/components/MainFooter')
const MainHeader = () => import(/* webpackChunkName: "main" */ '@/components/MainHeader')

const mainComponents = (content, modal) => ({
	content,
	footer: MainFooter,
	header: MainHeader,
})

export const routes = [
	{
		path: '/',
		component: Main,
		children: [
			{
				name: 'main',
				path: '',
				components: mainComponents(Home),
			},
			{
				name: 'seed',
				path: 'seed/:seedId',
				components: mainComponents(Seed),
			},
			{
				name: 'contact',
				path: 'contact',
				components: mainComponents(Contact),
			},
		],
	}
]
