<template>
	<div class="confirm mdc-dialog"
		role="alertidalog"
		aria-modal="true"
		aria-labelledby="dialog_title"
		area-describedby="dialog_content">
		<div class="mdc-dialog__container">
			<div class="mdc-dialog__surface">
				<h2 class="mdc-dialog__title" id="dialog_title">{{options.title}}</h2>
				<div class="mdc-dialog__content" id="dialog_content">
					{{ options.message }}
				</div>
				<footer class="mdc-dialog__actions">
					<button type="button" class="mdc-button mdc-dialog__button" data-mdc-dialog-action="close" @click="options.cancelAction()" v-if="options.showCancel">
						<span class="mdc-button__label">{{options.cancelLabel}}</span>
					</button>
					<button type="button" class="mdc-button mdc-dialog__button" data-mdc-dialog-action="accept" @click="options.acceptAction()">
						<span class="mdc-button__label">{{options.acceptLabel}}</span>
					</button>
				</footer>
			</div>
		</div>
		<div class="mdc-dialog__scrim"></div>
	</div>
</template>

<script>
// Dependencies
import { MDCDialog } from '@material/dialog'

// Component
export default {
	name: 'confirmPlugin',
	data: () => ({
		dialog: null,
		defaults: {
			title: 'Are you sure?',
			acceptLabel: 'Confirm',
			acceptAction: () => ({}),
			cancelLabel: 'Cancel',
			cancelAction: () => ({}),
			showCancel: true,
		},
		options: {},
	}),
	mounted() {
		this.dialog = new MDCDialog(this.$el)
	},
	methods: {
		show(opts) {
			this.options = Object.assign({}, this.defaults, opts)
			this.dialog.open()
		},
		close() {
			this.dialog.close()
		},
	},
}
</script>

<style scoped lang="scss">
@use '@material/button';
@use '@material/dialog/mdc-dialog';

.mdc-dialog {
	&__content {
		white-space: pre-line;
	}
}
</style>
