import { API } from '@/lib/api'
import { camelCase } from 'lodash'

const SHEET_ID = process.env.SHEET_ID || process.env.VUE_APP_SHEET_ID
const API_KEY = process.env.API_KEY || process.env.VUE_APP_API_KEY

const state = () => ({})

function feedToData(input) {
	let data = []
	const { values } = input
	let keys = values.shift()
	const rows = values
	keys = keys.map(k => camelCase(k))

	if (!rows.length) return data

	for (const row of rows) {
		let _row = {}
		for (let i = 0; i < keys.length; i++) {
			_row[keys[i]] = row[i]
		}

		data.push(_row)
	}

	return data
}

const actions = {
	async load({ commit }, sheet_num) {
		this.dispatch('misc/setLoading', true)

		let data
		try {
			let feed = await API.get(`${SHEET_ID}/values/${sheet_num}`, { alt: 'json', key: API_KEY, })
			data = feedToData(feed)
			this.dispatch('misc/setLoading', false)
		} catch (error) {
			this.dispatch('misc/setLoading', true)
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error)
		}

		return data
	}
}

const mutations = {}

const getters = {}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
