<template>
	<router-view id="app" />
</template>

<style lang="scss">
@import 'styles/partials';

#app {
	font-family: 'Raleway', sans-serif;
	min-height: 100vh;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
</style>

<script>
export default {
	name: 'app',
	created() {
		document.addEventListener('SWUpdated', this.showRefresh)
	},
	mounted() {
		// TODO: Auth Check
	},
	methods: {
		showRefresh(evt) {
			this.$notice('New version available!', () => {
				evt.detail.postMessage({action: 'skipWaiting'})
			}, {
				actionLabel: 'Click Here to Update',
				timeout: 10000,
			})
		},
	},
	metaInfo: () => ({
		title: `Seeds`,
		titleTemplate: `%s | Northern Star Seed LLC`,
		meta: [
			{
				charset: 'utf-8'
			},
			{
				'http-equiv': 'X-UA-Compatible',
				content: 'IE-edge',
			},
			{
				name: 'description',
				content: '',
			},
			{
				name: 'viewport',
				content: 'width=device-width, initial-scale=1, maximum-scale=5',
			},
		],
		link: [
			{
				rel: 'preconnect',
				href: 'https://fonts.googleapis.com',
			},
			{
				rel: 'preconnect',
				href: 'https://fonts.gstatic.com',
				crossorigin: true,
			},
			{
				rel: 'stylesheet',
				href: 'https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700;800;900&family=Open+Sans:wght@600;700;800&display=swap',
			},
			{
				rel: 'stylesheet',
				href: 'https://fonts.googleapis.com/icon?family=Material+Icons',
			},
		],
		script: [
			{
				type: 'application/ld+json',
				json: {
					"@context" : "http://schema.org",
					"@type" : "LocalBusiness",
					"name": "Northern Star Seed LLC",
					"image" : "http://northernstarseed.com/img/icon/logo.png",
					"telephone" : "+1-317-498-6476",
					"address" : {
						"@type" : "PostalAddress",
						"streetAddress" : "114 S. Earl Ave.",
						"addressLocality" : "Lafayette,",
						"addressRegion" : "IN",
						"addressCountry": "USA",
						"postalCode" : "47904"
					}
				}
			},
		],
	}),
}
</script>
