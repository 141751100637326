///
/// Store Action Types
///

/// MENU
export const MENU_TOGGLE = 'MENU_TOGGLE'

/// MISC
export const SET_LOADING = 'SET_LOADING'
export const SET_ERROR = 'SET_ERROR'

/// AUTHENTICATION
export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const SET_ME = 'SET_ME'
export const UNSET_ME = 'UNSET_ME'
export const SET_USER = 'SET_USER'
export const UNSET_USER = 'UNSET_USER'
export const SET_PROFILE = 'SET_PROFILE'
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING'
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR'

export const SET_SEEDS = 'SET_SEEDS'
export const SET_SEED = 'SET_SEED'
export const UNSET_SEED = 'UNSET_SEED'
