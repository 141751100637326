import { API } from '@/lib/api'
import * as types from '../types'
import { camelCase } from 'lodash'

const SHEET_ID = process.env.SHEET_ID || process.env.VUE_APP_SHEET_ID
const API_KEY = process.env.API_KEY || process.env.VUE_APP_API_KEY

function feedToData(input) {
	let data = []
	const { values } = input
	let keys = values.shift()
	const rows = values
	keys = keys.map(k => camelCase(k))

	if (!rows.length) return data
	const counts = {}
	for (const k of keys) {
		counts[k] = counts[k] ? counts[k] + 1 : 1
	}

	for (const row of rows) {
		let _row = {}
		for (let i = 0; i < keys.length; i++) {
			if (counts[keys[i]] > 1) {
				_row[keys[i]] = _row[keys[i]] ? _row[keys[i]] : []
				_row[keys[i]].push(row[i])
			} else {
				_row[keys[i]] = row[i]
			}
		}

		data.push(_row)
	}

	return data
}

const state = () => ({
	seeds: [],
	seed: {},
})

const actions = {
	async list({ commit }) {
		this.dispatch('misc/setLoading', true)

		let data
		try {
			let res = await API.get(`${SHEET_ID}/values/home`, { alt: 'json', key: API_KEY, })
			data = feedToData(res)
			commit(types.SET_SEEDS, data)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
		} finally {
			this.dispatch('misc/setLoading', false)
		}

		return data
	},
	async get({ commit }, seedId) {
		this.dispatch('misc/setLoading', true)

		let data
		try {
			let res = await API.get(`${SHEET_ID}/values/${seedId}`, { alt: 'json', key: API_KEY, })
			data = feedToData(res)
			commit(types.SET_SEED, { seedId, seed: data })
		} catch (error) {
			console.error(error)
			this.dispatch('misc/setError', error.message || error)
		} finally {
			this.dispatch('misc/setLoading', false)
		}

		return data
	},
	unset({ commit }) {
		commit(types.UNSET_SEED)
	},
}

const mutations = {
	[types.SET_SEEDS](state, seeds) {
		state.seeds = seeds
	},
	[types.SET_SEED](state, { seedId, seed }) {
		let match = state.seeds.find(s => s.key == seedId)
		state.seed = {...match, ...{
			items: seed,
		}}
	},
	[types.UNSET_SEED](state) {
		state.seed = {}
	},
}

const getters = {
	seeds: state => state.seeds,
	seed: state => state.seed,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
