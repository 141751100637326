import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import { router } from './lib/router'
import { store } from './lib/store'

import VueMeta from 'vue-meta'
Vue.use(VueMeta)
import confirm from './lib/plugins/confirm'
Vue.use(confirm)
import notice from './lib/plugins/notice'
Vue.use(notice)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
